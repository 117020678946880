/*eslint-disable */
import React, { useEffect, useState } from "react"
import { Row } from "antd"
import FormindIviduals from "../components/contactcomponts/contactForm/formindividuals"
import useTranslations from "../components/useTranslations"
import SEO from "../components/seo"
import WhatsappImg from "../assets/footericons/brandIcons/whatsapp (header).svg"
import ViberImg from "../assets/footericons/brandIcons/viber(header)svg.svg"
import TelegramImg from "../assets/footericons/brandIcons/telegram (header).svg"
import {
  ContactPageWrapper,
  TelegramWrapper,
  ViberWrapper,
  WhatsappWrapper,
} from "../components/contactcomponts/contactForm/formStyle"
import {
  AdressColumn,
  AdressMapCol,
  ContactColumn,
  FacebookIcon,
  FacebookShare,
  FormColumn,
  FormRow,
  H1Email,
  H1Styled,
  H3Element,
  HeadingParagrCol,
  InfoColumn,
  LinkdinIcon,
  LinkedinShare,
  MapCol,
  Mapiframe,
  ParagraphRow,
  PElement,
  PStyled,
  ShareColumn,
  SharedWrapperCol,
} from "../components/contactcomponts/contactMainStyle"
import SharedLogo from "../assets/sharedLogo/share_logo(3c).png"
import ReactWhatsapp from "react-whatsapp"
import ViberLink from "react-viber-link"

const Contact = ({ pageContext }) => {
  const { contact } = useTranslations()

  return (
    <>
      <ContactPageWrapper>
        <SEO
          title={contact.title}
          description={contact.paragraph}
          pageContext={pageContext}
          image={SharedLogo}
        />
        <ParagraphRow>
          <HeadingParagrCol lg={{ span: 24 }} xxl={{ span: 8, offset: 4 }}>
            <H1Styled>{contact.title}</H1Styled>
            <PStyled>{contact.paragraph}</PStyled>
          </HeadingParagrCol>
        </ParagraphRow>
        <FormRow align="middle">
          <>
            <InfoColumn>
              <H1Email>info@triple-c.am</H1Email>
              <Row>
                <AdressColumn>
                  <H3Element>{contact.contact_address}</H3Element>
                  <PElement>{contact.address}</PElement>
                </AdressColumn>
                <ContactColumn style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                  <H3Element style={{ width: "100%" }}>{contact.tel}</H3Element>
                  <PElement>+374 98553533 +374 60407010</PElement>
                  <ReactWhatsapp number="+37498553533">
                    <WhatsappWrapper src={WhatsappImg} />
                  </ReactWhatsapp>
                  <ViberLink number="+37498553533">
                    <ViberWrapper src={ViberImg} />
                  </ViberLink>

                  <a target={"_blank"} href=" https://telegram.me/TripleConsulting">
                    <TelegramWrapper src={TelegramImg} />
                  </a>
                </ContactColumn>
                <ShareColumn>
                  <SharedWrapperCol>
                    <H3Element>{contact.follow_us}</H3Element>
                    <FacebookShare
                      href={"https://www.facebook.com/TripleCArmenia/"}
                      children={<FacebookIcon />}
                    />
                    <LinkedinShare
                      href={"https://www.linkedin.com/company/triple-consulting/"}
                      children={<LinkdinIcon />}
                    />
                  </SharedWrapperCol>
                </ShareColumn>
              </Row>
            </InfoColumn>
            <FormColumn>
              <FormindIviduals langtext={contact.form_content} lang={pageContext.locale} />
            </FormColumn>
          </>
        </FormRow>
      </ContactPageWrapper>
      <>
        <AdressMapCol>
          <>
            <MapCol>
              <Mapiframe
                frameborder="0"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3047.302539302384!2d44.501995115644156!3d40.202332676574336!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x406abdf62e936839%3A0xb8915a1f9ac2a0b9!2sTriple%20Consulting!5e0!3m2!1sru!2s!4v1623411713053!5m2!1sru!2s"
              />
            </MapCol>
          </>
        </AdressMapCol>
      </>
    </>
  )
}

export default Contact
